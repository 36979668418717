// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, doc, getDoc, updateDoc } from "firebase/firestore"; // Import getFirestore, doc, and getDoc

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC78O3q-F5UQUYa3UbM9jC71ZvotldGscg",
    authDomain: "betancor-7f612.firebaseapp.com",
    projectId: "betancor-7f612",
    storageBucket: "betancor-7f612.appspot.com",
    messagingSenderId: "175448496614",
    appId: "1:175448496614:web:33f7e6b80478515be99e27"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Obtén una referencia a Firestore
const db = getFirestore(app); // Pass the app instance to getFirestore

// Exportar la referencia a Firestore
export { db, collection, addDoc, getDocs, doc, getDoc, updateDoc };