import logo from "../assets/img/logo-betancor.png"

function NavBar(){
    return (
        <header id="header" style={{width: "100%",
            backgroundColor: "transparent",
            top: "0",
            left: "0",
            zIndex: "1000",}}>
         <div class="nav container">
            
            <a href="#" class="nav__logo">
               <img src={logo} alt=""/>
            </a>
         </div>
      </header>
    )
}

export default NavBar;