// src/App.js
import React, { useState } from 'react';
import NavBar from './fragments/NavBar';
import { db, addDoc, collection } from './firebase/firebase';
import { useNavigate } from "react-router-dom";
function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    destination: '',
    startDate: '',
    endDate: '',
    adults: 1,
    children: 0,
    observations: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  {/*const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Ajusta el regex según el formato de teléfono esperado
    return phoneRegex.test(phone);
  };*/}

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Nombre y Apellido es requerido';
    if (!formData.email) {
        newErrors.email = 'Email es requerido';
    } else if (!validateEmail(formData.email)) {
        newErrors.email = 'Email no tiene un formato válido';
    }
    if (!formData.phone) {
        newErrors.phone = 'Teléfono es requerido';
    }{/* else if (!validatePhone(formData.phone)) {
        newErrors.phone = 'Teléfono no tiene un formato válido';
    }*/}
    if (!formData.destination) newErrors.destination = 'Ciudad de Destino es requerido';
    if (!formData.startDate) newErrors.startDate = 'Fecha de Salida es requerida';
    if (!formData.endDate) newErrors.endDate = 'Fecha de Regreso es requerida';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(formData);
      addDocument("forms", formData)
      /**/ // Navega al sitio web
    } else {
      console.log('Validation failed');
    }
  };

  async function addDocument(collectionName, documentData) {
    try {
      // Referencia a la colección
      const docRef = await addDoc(collection(db, collectionName), documentData);
      console.log("Documento agregado con ID: ", docRef.id);
      window.location.href = "https://betancorviajes.com.uy/";
      

    } catch (e) {
      console.error("Error al agregar el documento: ", e);
    }
  }

  return (
<div>
    <NavBar/>
    <div className="h-2"></div>
    <div className="min-h-screen flex items-center justify-center bg-black">
      <form className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg" onSubmit={handleSubmit}>
        <h1 className="mt-5 font-bold text-3xl text-black text-center mb-6">Cotización</h1>

        <div className="mb-4">
          <label className="block text-gray-700">Nombre y Apellido</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg bg-slate-100"
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg bg-slate-100"
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Teléfono</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg bg-slate-100"
          />
          {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Ciudad de Destino</label>
          <input
            type="text"
            name="destination"
            value={formData.destination}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg bg-slate-100"
          />
          {errors.destination && <p className="text-red-500 text-xs mt-1">{errors.destination}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Fecha de Salida y Regreso</label>
          <div className="flex space-x-2">
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              className="w-1/2 px-3 py-2 border rounded-lg bg-slate-100"
            />
            {errors.startDate && <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>}
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              className="w-1/2 px-3 py-2 border rounded-lg bg-slate-100"
            />
            {errors.endDate && <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Adultos</label>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => setFormData({ ...formData, adults: Math.max(1, formData.adults - 1) })}
              className="px-2 py-1 bg-gray-300 rounded bg-black text-white"
            >
              -
            </button>
            <input
              type="number"
              name="adults"
              value={formData.adults}
              onChange={handleChange}
              className="w-12 text-center px-2 py-1 border rounded-lg bg-slate-100"
              readOnly
            />
            <button
              type="button"
              onClick={() => setFormData({ ...formData, adults: formData.adults + 1 })}
              className="px-2 py-1 bg-gray-300 rounded bg-black text-white"
            >
              +
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Menores</label>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => setFormData({ ...formData, children: Math.max(0, formData.children - 1) })}
              className="px-2 py-1 bg-gray-300 rounded bg-black text-white"
            >
              -
            </button>
            <input
              type="number"
              name="children"
              value={formData.children}
              onChange={handleChange}
              className="w-12 text-center px-2 py-1 border rounded-lg bg-slate-100"
              readOnly
            />
            <button
              type="button"
              onClick={() => setFormData({ ...formData, children: formData.children + 1 })}
              className="px-2 py-1 bg-gray-300 rounded bg-black text-white"
            >
              +
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Observaciones</label>
          <textarea
            name="observations"
            value={formData.observations}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
          ></textarea>
        </div>

        <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg">Enviar</button>
      </form>
    </div>
    <div className="h-2"></div>
</div>
  );
}

export default App;
